import React from "react"
import FlexContentFull from "./FlexContentFull"
import FlexContentHalf from "./FlexContentHalf"

const FlexContent = props => {
  const flexContentParts = props.flexContent
  let key = 0

  return (
    <div id="flex-content">
      <div className="grid">
        <div className="row">
          <div className="col lg-10-12 lg-push-left-1-12">
            <div className="flex-content-container">
              {flexContentParts &&
                flexContentParts.map(part => {
                  key++
                  if (part.__typename === "WordPressAcf_full_width") {
                    return (
                      <FlexContentFull
                        content={part.text}
                        key={"flex-content-" + key}
                      />
                    )
                  } else if (part.__typename === "WordPressAcf_half_width") {
                    return (
                      <FlexContentHalf
                        content={part.text_blocks}
                        key={"flex-content-" + key}
                      />
                    )
                  } else {
                    return undefined
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FlexContent
