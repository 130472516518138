import React from "react"
import Layout from "../layouts/layout"
import FlexContentController from "../components/FlexContent"

const defaultPage = ({ data, pageContext }) => {
  const pageTitle = data.defaultPageInfo.title
  const flexContent = data.defaultPageInfo.acf.flex_content_page

  return (
    <Layout
      lang={pageContext.lang}
      tpl={pageContext.templateGT}
      theme="white"
      intro={false}
    >
      <section id="intro" className="intro--plain">
        <div className="grid">
          <div className="row">
            <div className="col lg-10-12 lg-push-left-1-12">
              <h1 dangerouslySetInnerHTML={{ __html: pageTitle }} />
            </div>
          </div>
        </div>
      </section>
      {flexContent && <FlexContentController flexContent={flexContent} />}
    </Layout>
  )
}

export default defaultPage

export const query = graphql`
  query($id: Int) {
    defaultPageInfo: wordpressPage(wordpress_id: { eq: $id }) {
      title
      acf {
        flex_content_page {
          __typename
          ... on WordPressAcf_full_width {
            text
          }
          # ... on WordPressAcf_half_width {
          #   text_blocks {
          #     text
          #   }
          # }
        }
      }
    }
  }
`
