import React from "react"

const flexContentHalf = props => {
  let key = 0
  const parts = props.content
  return (
    <div className="flex-content__part flex-content__part--half">
      {parts &&
        parts.map(part => {
          key++
          return (
            <article
              key={`flex-content-half-${key}`}
              dangerouslySetInnerHTML={{ __html: part.text }}
            />
          )
        })}
    </div>
  )
}
export default flexContentHalf
